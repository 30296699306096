.News {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    padding: 20px;
}

@media (max-width: 1600px) {
    .News {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 968px) {
    .News {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .News {
        grid-template-columns: repeat(1, 1fr);
    }
}

.card-img {
    height: 250px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.Card {
    background-color: white;
    box-shadow: 0 0 3px #000000;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s;
}

.News a {
    color: black;
    text-decoration: none;
    display: inherit;
}

.News .Card:hover {
    box-shadow: 0 0 15px #000000;
    filter: brightness(1.1);
}

.News .Card:active {
    scale: 1.05;
}

.News header {
    font-weight: bold;
}

.News header, .News footer {
    padding: 4px 8px;
}

.article {
    max-width: 700px;
    margin: auto;
    background-color: white;
    padding: 1px 0px;
}

.article h3 {
    margin: 0;
    padding: 10px;
}

.article p {
    margin: 0;
    padding: 14px 10px;
}