.Talkbacks .talkbackContainer {
    box-shadow: 0 0 4px #7e7e7e;
    margin: 12px;
    padding: 12px 8px;
    border-radius: 6px;
}

.Talkbacks .talkbackContainer .grid {
    display: grid;
    grid-template-columns: 50px 3fr 80px;
    gap: 5px;
}

.Talkbacks .grid div:first-child {
    grid-row: 2 span;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Talkbacks .btnFrame {
    display: flex;
    align-items: center;
    grid-row: 2 span;
    justify-self: end;
    padding-left: 4px;
}

.Talkbacks .content {
    font-size: 1.2em;
}

.talkbackContainer i {
    color: gray;
    font-size: 12px;
    font-style: normal;
}

.TalkbacksForm {
    display: flex;
    justify-content: end;
}

.TalkbacksForm form {
    max-width: 400px;
    padding: 12px;
    border-radius: 3px;
    border: 1px solid #d0d0d0;
    margin: 18px 0 0 0;
}

.TalkbacksForm.block {
    display: block;
}

.TalkbacksForm.block form {
    max-width: 100%;
    margin: 12px;
}

.TalkbacksForm form button {
    margin: 12px 0 0 0;
}

.circle {
    background: gray;
    color: white;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}