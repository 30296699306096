.tokenFrame {
    max-width: 300px;
    margin: auto;
    display: flex;
}

.tokenFrame input {
    Margin: 0;
}

.clearToken {
    position: absolute;
    left: 8px;
    top: 8px;
}